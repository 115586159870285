function NotFound() {
  return (
    <div className="w-full h-screen flex justify-center items-center">
      <div className="text-center">
        <p className="text-8xl font-bold text-blue-600">404</p>
        <p className="text-2xl font-bold text-blue-600">Page Not Found</p>
      </div>
    </div>
  );
}

export default NotFound;
