export const About = () => {
  return (
    <section id="about" className="pt-4">
      <p className="text-center font-bold text-3xl text-blue-900">About Me</p>
      <div className="container mx-auto py-12 px-8 lg:px-4 w-full lg:w-3/4 md:flex md:items-center">
        <img
          className="my-2 object-cover object-center rounded-lg w-32 h-32 md:w-48 md:h-48 mx-auto mb-8"
          alt="hero"
          src={"/assets/icons/icon-doctor.png"}
        />
        <div className="ml-8">
          <p className="my-2 text-base leading-relaxed text-center text-gray-700 lg:text-left lg:text-1xl">
            Dr B Ramesh is the Head of Cardiology Department at Apollo Hospitals. Previously he was a Professor of Cardiology in Sri Jayadeva Institute of Cardiology, Bengaluru. 
            He joined this institute in 1987 as a registrar in DM Cardiology, completed DM in 1989, became Assistant Professor in 1990, and Professor in 1995.
          </p>
          <p className="my-2 text-base leading-relaxed text-center text-gray-700 lg:text-left lg:text-1xl">
            He trains DM cardiology fellows from all over India. 
            He is one of the pioneering Interventional cardiologist from Bengaluru
            having performed thousands of Angioplasties, Balloon mitral Valvuloplasties, 
            pacemaker implantations in his long career of more than 35 years.
            He started as a consultant cardiologist at Apollo Hospital, 
            Bengaluru since its inception in 2007 and now heads the Cardiology Department.
          </p>
          <p className="my-2 text-base leading-relaxed text-center text-gray-700 lg:text-left lg:text-1xl">
            He started Ramesh Cardiology Clinic in Banashankari 2nd stage, in 2011 with state 
            of the art facilities for diagnosis and management of cardiac ailments.
            You can make an appointment by calling the contact number below.
          </p>
        </div>
      </div>
    </section>
  );
};
