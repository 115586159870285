import {
  Navbar,
  Header,
  About,
  Achievements,
  Investigations,
  Testimonial,
  Appoinment,
  Contact,
  Footer,
} from "./../components";

function Home() {
  return (
    <div className="App">
      <Navbar />
      <Header />
      <About />
      <Investigations />
      <Achievements />
      <Testimonial />
      {/* <Appoinment /> */}
      <Contact />
      <Footer />
    </div>
  );
}

export default Home;
