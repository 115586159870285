export const Header = () => {
  return (
    <section id="header" className="text-gray-700 body-font">
      <div className="container flex flex-col items-center px-5 py-16 mx-auto lg:px-20 lg:py-24 md:flex-row">
        <div className="flex flex-col items-center w-full pt-0 mb-16 text-left lg:flex-grow md:w-1/2 lg:mr-20 lg:pr-24 md:pr-16 md:items-start md:text-left md:mb-0 lg:text-center">
          <h1 className="mb-8 text-2xl font-bold tracking-tighter text-center text-blue-600 md:text-left lg:text-left lg:text-5xl title-font">
            Hello, <br />I am Dr Ramesh
          </h1>
          <p className="mb-8 text-base leading-relaxed text-center text-gray-700  md:text-left lg:text-left lg:text-1xl">
            I am a Cardiologist and I welcome you to my Clinic in Banashankari 2nd Stage, Bengaluru. Its a one stop
            for all cardio investigations you need.
          </p>
          {/* <div className="flex justify-center">
            <button className="items-center px-8 pt-2 pb-3 mt-4 font-semibold text-white transition duration-500 ease-in-out transform  bg-blue-600 border rounded-full lg:inline-flex lg:mt-px hover:border-blue-800 hover:bg-blue-700 hover:text-white focus:ring focus:outline-none">
              Make an Appoinment
            </button>
          </div> */}
        </div>
        <div className="w-5/6 lg:max-w-lg lg:w-full md:w-1/2">
          <img
            className="object-cover object-center rounded-lg"
            alt="hero"
            src={"/assets/images/dr-ramesh.png"}
          />
        </div>
      </div>
    </section>
  );
};
