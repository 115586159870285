export const Navbar = () => {
  return (
    <nav
      className="text-gray-700 bg-white border-t border-b body-font"
      id="navbar"
    >
      <div className="container flex flex-col flex-wrap p-5 mx-auto md:items-center md:flex-row ">
        <div className="text-center mx-auto md:mx-px flex-row md:flex justify-center items-center mb-4 md:mb-0">
          <img
            className="w-11 h-11 md:mr-2 mx-auto md:mx-px"
            alt="hero"
            src="./assets/icons/logo-ramesh-clinic.png"
          />
          <a
            href="#navbar"
            className="text-center flex items-center mt-1 md:mt-0 mb-4 font-bold text-gray-900 text-2xl md:mb-0"
          >
            Ramesh Cardiology Clinic
          </a>
        </div>
        <nav className="flex flex-wrap items-center justify-center text-base md:ml-auto ">
          <a
            href="#navbar"
            className="mr-5 text-md font-semibold text-gray-600 hover:text-gray-800"
          >
            Home
          </a>
          <a
            href="#about"
            className="mr-5 text-md font-semibold text-gray-600 hover:text-gray-800"
          >
            About
          </a>
          <a
            href="#investigations"
            className="mr-5 text-md font-semibold text-gray-600 hover:text-gray-800"
          >
            Services
          </a>
          <a
            href="#testimonial"
            className="mr-5 text-md font-semibold text-gray-600 hover:text-gray-800"
          >
            Testimonial
          </a>
          <a
            href="#contact"
            className="mr-5 text-md font-semibold text-gray-600 hover:text-gray-800"
          >
            Contact
          </a>
        </nav>
        {/* <a
          href="#appointment"
          className="hidden lg:block text-center items-center text-sm px-6 pt-1 pb-2 mt-4 font-semibold text-white transition duration-500 ease-in-out transform  bg-blue-600 border rounded-full lg:inline-flex lg:mt-px hover:border-blue-800 hover:bg-blue-700 hover:text-white focus:ring focus:outline-none"
        >
          Appointment
        </a> */}
      </div>
    </nav>
  );
};
