const achievementList = [
  {
    name: "GOOGLE",
    body: "75+ 5 Star Reviews",
    icon: "/assets/icons/icon-achievement-practo.png",
    url: "https://goo.gl/maps/vJ6Yj9fpaqQiV5bHA",
  },
  {
    name: "PRACTO",
    body: "933+ of 5 Star Reviews",
    icon: "/assets/icons/icon-achievement-practo.png",
    url: "https://www.practo.com/bangalore/clinic/dr-ramesh-cardiology-clinic-banashankari-2nd-stage/reviews",
  },
];

export const Achievements = () => {
  return (
    <section id="achievements" className="pt-4">
      <p className="text-center font-bold text-3xl text-blue-900">
        Our Visitors Love Us
      </p>
      <div >
      <div className="md:flex md:justify-center md:items-center mx-auto">
        {achievementList.map((val, index) => (
          <a
            href={val.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div
              key={index}
              className="p-8 shadow-md rounded-md my-8 mx-16 md:mx-4 text-center"
            >
              <img
                className="object-cover object-center rounded-lg w-12 h-12 mx-auto my-4"
                alt="hero"
                src={val.icon}
              />
              <p className="font-bold text-2xl text-gray-800 tracking-wider">
                {val.name}
              </p>
              <p className="font-medium text-sm text-blue-600 tracking-wider">
                {val.body}
              </p>
              {[...Array(5)].map((star, index) => (
                <img
                  key={index}
                  alt="testimonial"
                  className="inline-block object-cover object-center w-4 h-4 mt-4 mr-1"
                  src="./assets/icons/icon-star.png"
                />
              ))}
            </div>
          </a>
        ))}
      </div>
    </div>
    </section>
  );
};
