import { Switch, Route, Redirect } from "react-router-dom";
import { routeList } from "./routes";

import "./App.css";

function App() {
  return (
    <div className="App">
      <Switch>
        {routeList.map((route: any) => (
          <Route
            key={route.path}
            path={route.path}
            component={route.component}
            exact={route.exact}
          />
        ))}

        <Redirect to="/not-found" />
      </Switch>
    </div>
  );
}

export default App;
