function Login() {
  return (
    <div className="w-full h-screen flex justify-center items-center">
      <div className="text-center">
        <p className="text-2xl font-bold text-blue-600">
          This is an example page.
        </p>
      </div>
    </div>
  );
}

export default Login;
