import { useState } from "react";
import { Formik } from "formik";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";
import * as Yup from "yup";
import Recaptcha from "react-recaptcha";

const schema = Yup.object().shape({
  name: Yup.string().required("This field is required"),
  email: Yup.string()
    .email("Invalid format email")
    .required("This field is required"),
  phone: Yup.string().required("This field is required"),
  service: Yup.string().required("This field is required"),
  date: Yup.string().required("This field is required").nullable(),
  time: Yup.string().required("This field is required"),
  message: Yup.string(),
});

const serviceList = [
  "Electrocardiogram",
  "Color Doppler Echocardiography",
  "Treadmill Stress Test",
  "Holter Monitoring",
];

const currentDate = new Date();

export const Appoinment = () => {
  const [timeOpt, setTimeOpt] = useState([] as string[]);
  const [resMessage, setResMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isShowCaptcha, setIsShowCaptcha] = useState(false);
  const [dataForm, setDataForm] = useState({} as any);

  const getTimeSlotOptions = (date: any) => {
    const timeList = ["6.30", "7.30"];
    setTimeOpt(timeList);
    const dateFormatted = moment(date).format("YYYY-MM-DD");

    console.log("get time slot", { dateFormatted, timeList });
  };

  const handleSubmit = (values: any) => {
    setIsSubmitting(true);
    setIsShowCaptcha(true);

    setDataForm(values);
  };

  const submitAppointment = () => {
    const dateFormatted = moment(dataForm?.date).format("YYYY-MM-DD");
    const payload = {
      patientName: dataForm?.name,
      patientPhone: dataForm?.phone,
      patientEmail: dataForm?.email,
      slot: dataForm?.time,
      service: dataForm?.service,
      message: dataForm?.message,
    };

    console.log("post ", { dateFormatted, payload });
    setResMessage("You have an appointment!");
    setIsSubmitting(false);
  };

  const handleCaptchaVerified = (res: any) => {
    if (res) {
      submitAppointment();
    }
  };

  return (
    <section id="appointment" className="bg-blue-500 my-8 py-8">
      <section className="container mx-auto">
        <section className="p-8 rounded-lg bg-white w-full lg:w-1/2 my-4 mx-auto shadow-md">
          <div className="">
            <p className="text-center font-bold text-3xl text-gray-700 mb-2">
              Make an Appoinment
            </p>
            <p className="text-gray-700 text-center">We are here for you!</p>
            <Formik
              initialValues={{
                name: "",
                email: "",
                phone: "",
                service: "",
                date: null,
                time: "",
                message: "",
              }}
              validationSchema={schema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                touched,
                errors,
              }) => (
                <div className="mt-4">
                  <div className="my-2 grid grid-cols-1 md:grid-cols-2 gap-2">
                    <div className="w-full">
                      <label className="font-semibold text-sm">Name</label>
                      <input
                        name="name"
                        className="w-full py-2 px-4 rounded bg-white border border-solid border-gray-400 focus:outline-none focus:ring focus:border-blue-500"
                        // placeholder={`Your Name...`}
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.name && touched.name && (
                        <p className="text-xs text-red-500">{errors.name}</p>
                      )}
                    </div>
                    <div className="w-full">
                      <label className="font-semibold text-sm">Email</label>
                      <input
                        name="email"
                        className="w-full py-2 px-4 rounded bg-white border border-solid border-gray-400 focus:outline-none focus:ring focus:border-blue-500"
                        // placeholder={`Your Email...`}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.email && touched.email && (
                        <p className="text-xs text-red-500">{errors.email}</p>
                      )}
                    </div>
                    <div className="w-full">
                      <label className="font-semibold text-sm">Phone</label>
                      <input
                        name="phone"
                        className="w-full py-2 px-4 rounded bg-white border border-solid border-gray-400 focus:outline-none focus:ring focus:border-blue-500"
                        // placeholder={`Your Phone...`}
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.phone && touched.phone && (
                        <p className="text-xs text-red-500">{errors.phone}</p>
                      )}
                    </div>
                    <div className="w-full">
                      <label className="font-semibold text-sm">Service</label>
                      <Select
                        classNamePrefix="react-select"
                        value={{ label: values.service, value: values.service }}
                        options={serviceList.map((val) => ({
                          label: val,
                          value: val,
                        }))}
                        components={{
                          IndicatorSeparator: () => null,
                          DropdownIndicator: () => null,
                        }}
                        onChange={(sel) => setFieldValue("service", sel?.value)}
                        // placeholder="Select Service"
                      />
                      {errors.service && touched.service && (
                        <p className="text-xs text-red-500">{errors.service}</p>
                      )}
                    </div>
                    <div className="w-full">
                      <label className="font-semibold text-sm">
                        Appointment Date
                      </label>
                      <DatePicker
                        dateFormat="yyyy-MM-dd"
                        selected={values.date}
                        onChange={(date: any) => {
                          setFieldValue("date", date);
                          getTimeSlotOptions(date);
                        }}
                        minDate={currentDate}
                        // placeholder="Your Date..."
                        customInput={
                          <input
                            name="date"
                            className="w-full py-2 px-4 rounded bg-white border border-solid border-gray-400 focus:outline-none focus:ring focus:border-blue-500"
                          />
                        }
                      />
                      {errors.date && touched.date && (
                        <p className="text-xs text-red-500">{errors.date}</p>
                      )}
                    </div>
                    <div className="w-full">
                      <label className="font-semibold text-sm">
                        Appoinment Time
                      </label>
                      <Select
                        classNamePrefix="react-select"
                        value={{ label: values.time, value: values.time }}
                        options={timeOpt.map((val) => ({
                          label: val,
                          value: val,
                        }))}
                        components={{
                          IndicatorSeparator: () => null,
                          DropdownIndicator: () => null,
                        }}
                        onChange={(sel) => setFieldValue("time", sel?.value)}
                        // placeholder="Select Time Slot"
                      />
                      {errors.time && touched.time && (
                        <p className="text-xs text-red-500">{errors.time}</p>
                      )}
                    </div>
                    {/* {[
                      "Your Name",
                      "Your Email",
                      "Your Phone",
                      "Select Service",
                      "Select Date",
                      "Select Time",
                    ].map((val) => (
                      <div className="w-full">
                        <input
                          className="w-full py-2 px-4 rounded bg-white border border-solid border-gray-400 focus:outline-none focus:ring focus:border-blue-500"
                          placeholder={`${val}...`}
                        />
                      </div>
                    ))} */}
                  </div>
                  <div className="my-2">
                    <label className="font-semibold text-sm">Message</label>
                    <textarea
                      name="message"
                      className="w-full py-2 px-4 rounded bg-white border border-solid border-gray-400 focus:outline-none focus:ring focus:border-blue-500"
                      // placeholder={`Message...`}
                      value={values.message}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.message && touched.message && (
                      <p className="text-xs text-red-500">{errors.message}</p>
                    )}
                  </div>
                  <div className="flex justify-center">
                    <button
                      disabled={isSubmitting}
                      type="submit"
                      onClick={(e) => handleSubmit()}
                      className={`items-center px-8 pt-2 pb-3 mt-4 font-semibold text-white transition duration-500 ease-in-out transform bg-blue-600 border rounded-lg lg:inline-flex lg:mt-px  hover:text-white focus:ring focus:outline-none ${
                        !isSubmitting
                          ? "hover:bg-blue-700 hover:border-blue-800 "
                          : "bg-opacity-50 hover:cursor-auto"
                      }`}
                    >
                      {!isSubmitting ? "Send Request" : "Submitting..."}
                    </button>
                  </div>
                  {isShowCaptcha && (
                    <div className="mt-4 mx-auto">
                      <Recaptcha
                        sitekey="6LcQX1kaAAAAANEdb8P-AfuUcQf5ctN6MyIvco4g"
                        render="explicit"
                        verifyCallback={handleCaptchaVerified}
                      />
                    </div>
                  )}
                  <p className="mt-4 text-sm text-center font-semibold text-green-500">
                    {resMessage}
                  </p>
                </div>
              )}
            </Formik>
          </div>
        </section>
      </section>
    </section>
  );
};
