export const Contact = () => {
  return (
    <section id="contact" className="pt-4">
      <p className="text-center font-bold text-3xl text-blue-900">Contact Us</p>
      <section className="text-gray-600 body-font relative">
        <div className="container px-5 py-24 mx-auto flex sm:flex-nowrap flex-wrap">
          <div className="w-full sm:h-64 lg:w-2/3 md:w-1/2 bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
            <iframe
              width="100%"
              height="100%"
              className="absolute inset-0"
              frameBorder="0"
              title="map"
              scrolling="no"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d248879.74296210907!2d77.571083!3d12.924041!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x33b35503193d033d!2sRamesh%20Cardiology%20Clinic!5e0!3m2!1sen!2sus!4v1612364355027!5m2!1sen!2sus"
              //   style="filter: grayscale(1) contrast(1.2) opacity(0.4);"
            ></iframe>
          </div>
          <div className="lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full mt-8 md:mt-0">
            <div className="bg-white relative rounded">
              <div className="lg:w-full">
                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                  ADDRESS
                </h2>
                <p className="mt-1">
                  #2231 ,23rd Cross ,KR Road (Near National Co-operative Bank)
                  Banashankari 2nd stage Bengaluru 560070 Karnataka
                </p>
                <p className="mt-1">Mon-Sat 5PM - 8PM</p>
              </div>

              <div className="lg:w-full my-4">
                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                  EMAIL
                </h2>
                <p className="text-blue-500 leading-relaxed">
                  rameshbcardio@gmail.com
                </p>
              </div>

              <div className="lg:w-full my-4">
                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">
                  PHONE
                </h2>
                <p className="leading-relaxed">080 42065404 (Office)</p>
                <p className="leading-relaxed">+91 9845026010 (Phone)</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};
