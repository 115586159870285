import Home from "./../pages/Home";
import Login from "../pages/Login";
import NotFound from "./../pages/NotFound";

export const routeList = [
  {
    exact: true,
    path: "/",
    component: Home,
  },
  {
    exact: true,
    path: "/login",
    component: Login,
  },
  {
    exact: true,
    path: "/not-found",
    component: NotFound,
  },
];
