import Slider from "react-slick";

const settings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
};

const sliderList = [
  {
    testimonials: [
      {
        name: "Arshad Hussain",
        review:
          "One of the most selfless and caring doctors ever met. Have known him for more than 3 decades and he has been one of the best doctors ever.",
        avatar:
          "https://lh3.googleusercontent.com/a-/AOh14GjOAUxG2PC_iLbI1HQTAIFkyLYg7Unt2Ju1u2Sa=w72-h72-p-c0x00000000-rp-br100",
        rating: 5,
        url: "https://goo.gl/maps/zpwvgTvoQHnxQoSL7",
      },
      {
        name: "Vanaraj Jayanna",
        review:
          "Great doctor operated for my father in law with 4 stents successfuly...",
        avatar:
          "https://lh3.googleusercontent.com/a-/AOh14GgGRokdSPJTc_F_t0bGpDd4bQCHPOwhWU-oorSCz7A=w72-h72-p-c0x00000000-rp-br100",
        rating: 5,
        url: "https://goo.gl/maps/WtNAvYcpMwHgZmes5",
      },
      {
        name: "Sandeep Gande",
        review:
          "The doctor is honestly the best one I have ever seen. He's very friendly, with a constant smile on his face, clearly amiable. Would definitely recommend anyone to visit him if you are thinking of visiting here.",
        avatar:
          "https://lh3.googleusercontent.com/a-/AOh14GhzKXVj7LfyO9jSCw_fkla4_36l6OtrGAwEklbMHEY=w72-h72-p-c0x00000000-rp-ba6-br100",
        rating: 5,
        url: "https://goo.gl/maps/hi2GrvsgX1MKBELC6",
      },
    ],
  },
  {
    testimonials: [
      {
        name: "Sushmita De",
        review:
          "I went to Dr. Ramesh for my dad. He is a very good doctor and treats patients with utmost care. He also shares details as and when needed. Definitely recommend.",
        avatar:
          "https://lh3.googleusercontent.com/a-/AOh14GiBI-QNfH2DNCm1PaHImB1bl_YMMe7cnMNcB6M1=w72-h72-p-c0x00000000-rp-br100",
        rating: 5,
        url: "https://goo.gl/maps/EMVaSAUioZ4exSL46",
      },
      {
        name: "Siddharth Shah",
        review:
          "Super cool doctor, he is very humble and will make you feel comfortable. He does not prescribe unnecessary medicines.",
        avatar:
          "https://lh3.googleusercontent.com/a-/AOh14Ggfkx-ASUFADthDpDfOJjwKLVH6gr27FqkEyixM2A=w72-h72-p-c0x00000000-rp-ba3-br100",
        rating: 5,
        url: "https://goo.gl/maps/w5DpEgKn15RhtDqw7",
      },
      {
        name: "Satyavhelu K",
        review:
          "Great experience of doctor , and treats patients good and gives suggestions for patients to keep healthy",
        avatar:
          "https://lh3.googleusercontent.com/a-/AOh14GiVzAhAvzQuXg71f1TBipgyMZzNL7ZAKJhYs6QnXw=w72-h72-p-c0x00000000-rp-br100",
        rating: 5,
        url: "https://goo.gl/maps/jRabNnWPRncLRPu66",
      },
    ],
  },
];

export const Testimonial = () => {
  return (
    <section id="testimonial" className="pt-4">
      <p className="text-center font-bold text-3xl text-blue-900">
        Testimonials
      </p>
      <section className="pb-6 w-full">
        <Slider {...settings}>
          {sliderList.map((val, index) => {
            return (
              <div key={index}>
                <section className="text-gray-700 body-font">
                  <div className="container px-8 pb-12 mx-auto pt-16 lg:px-4">
                    <div className="flex flex-wrap -m-4">
                      {val.testimonials.map((val, index) => (
                        <div
                          key={index}
                          className="p-8 mx-auto mb-6 lg:w-1/3 lg:mb-0"
                        >
                          <div className="h-full p-4 text-left border rounded-xl flex flex-col justify-between">
                            <p className="text-base font-normal leading-relaxed text-gray-700">
                              {val.review}
                            </p>
                            <div className="mt-8">
                              {/* <img
                                alt="testimonial"
                                className="inline-block object-cover object-center w-12 h-12 mb-4 rounded-full bg-4gray-100"
                                src={val.avatar}
                              /> */}
                              <a
                                href={val.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <span className="flex flex-col flex-grow ">
                                  <span className="font-bold text-gray-900 title-font">
                                    {val.name}
                                  </span>
                                </span>
                              </a>
                              <span className="flex mt-2">
                                {[...Array(val.rating)].map((star, index) => (
                                  <img
                                    key={index}
                                    alt="testimonial"
                                    className="inline-block object-cover object-center w-4 h-4 mb-4 mr-1"
                                    src="./assets/icons/icon-star.png"
                                  />
                                ))}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </section>
              </div>
            );
          })}
        </Slider>
      </section>
    </section>
  );
};
