const activitiesList = [
  {
    id: "",
    title: "Electrocardiogram",
    body:
      "An electrocardiogram (EKG or ECG) is done to: Check the heart's electrical activity; Find the cause of unexplained chest pain, which could be caused by a heart attack, inflammation of the sac surrounding the heart; and Find the cause of symptoms of heart disease, such as shortness of breath, dizziness, fainting, or rapid, irregular heartbeats.",
    img: "/assets/images/inv-electrocardiogram.jpg",
    url: "http://www.webmd.com/heart-disease/electrocardiogram",
  },
  {
    id: "",
    title: "Color Doppler Echocardiography",
    body:
      "An accurate & non-invasive assessment of the overall health of the heart with ultrasound. A transducer is placed on the chest wall of a patient and cardiovascular images are taken. Ventricular squeezing/relaxing & valve opening/closing motions in rhythm with heartbeat can be evaluated for abnormalities.",
    img: "/assets/images/inv-echocardiography.jpg",
    url: "http://dpamedical.com/Echocardiography.html",
  },
  {
    id: "",
    title: "Treadmill Stress Test",
    body:
      "Cardiovascular exercise stress testing in conjunction with an ECG has been established as one of the focal points in the diagnosis and prognosis of cardiovascular disease, specifically coronary artery disease",
    img: "/assets/images/inv-treadmill.jpg",
    url: "http://www.webmd.com/heart-disease/guide/stress-test--(dupe)",
  },
  {
    id: "",
    title: "Holter Monitoring",
    body:
      "Holter monitor is a portable device for continuously monitoring various electrical activity of the cardiovascular system for at least 24 hours . The Holter's most common use is for monitoring heart activity (electrocardiography or ECG).",
    img: "/assets/images/inv-holter.jpg",
    url: "http://en.wikipedia.org/wiki/Holter_monitor",
  },
];
export const Investigations = () => {
  return (
    <section id="investigations" className="pt-4">
      <p className="text-center font-bold text-3xl text-blue-900">
        Services Available
      </p>
      <section className="text-gray-700 body-font">
        <div className="container px-8 pt-24 pb-12 mx-auto lg:px-4">
          <div className="flex flex-wrap text-left">
            {activitiesList.map((val, index) => (
              <div key={index} className="px-8 py-6 lg:w-1/2 md:w-full">
                <div className="inline-flex items-center justify-center flex-shrink-0 w-12 h-12 mb-5 text-blue-800 bg-gray-200 rounded-full">
                  <img
                    className="my-2 object-cover object-center rounded-lg w-8 h-8"
                    alt="hero"
                    src={`/assets/icons/inv-${index}.png`}
                  />
                </div>
                <h2 className="mb-3 text-lg font-medium text-gray-700 title-font">
                  {val.title}
                </h2>
                <p className="mb-4 text-base leading-relaxed">{val.body}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </section>
  );
};
